(function($){
	$(".ImageSlider-wrapper").slick({
		dots: true,
		dotsClass: "ImageSlider-dots",
        prevArrow: $(".ImageSlider-prev"),
        nextArrow: $(".ImageSlider-next"),
		//prevArrow: '<button type="button" class="slick-prev ImageSlider--button ImageSlider-prev--button">PREV</button>',
		//nextArrow: '<button type="button" class="slick-next ImageSlider--button ImageSlider-next--button">NEXT</button>',
		/*responsive: [
			{
				breakpoint: 992,
				settings: {
					prevArrow: '<button type="button" class="slick-prev ImageSlider--button ImageSlider-prev--button-mobile"></button>',
					nextArrow: '<button type="button" class="slick-next ImageSlider--button ImageSlider-next--button-mobile"></button>'
				}
			}
		]*/
	});

	var slidedots = $('.ImageSlider-dots button');
	for(var i = 0; i < slidedots.length; i++){
		slidedots[i].innerHTML = "";
	}

})(jQuery);
