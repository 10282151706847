(function($){
	$('input[type="submit"]').addClass("Basic-button");
	$('.contact-file_upload label').addClass("Basic-link");



	var file = $(".contact-file_upload input");
	var form = $(".contact-file_upload");
	var p = $("<p class='contact_file-name'> </p>");

		
	$(document).ready(function(){
	   	file.change(function(e){
	   		p.html("");
	        var fileName = e.target.files[0].name;
	        p.html(String(fileName));
	        form.append(p);
	    });
	})
	
})(jQuery);
