(function($){
  $("[data-imageslideshow]").slick({
      autoplay: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 6000,
      fade: true,
      arrows: true,
      slidesToShow: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      prevArrow: '<button type="button" class="slick-prev PortfolioSlider--button PortfolioSlider-prev--button Portfolio-prev--btn"></button>',
      nextArrow: '<button type="button" class="slick-next PortfolioSlider--button PortfolioSlider-next--button Portfolio-next--btn"></button>',
      dots: true
  });
})(jQuery);