(function($){

	$(".TestimonialSlider-slides").slick({
       autoplay: true,
		arrows: true,
		slidesToShow: 1,
		centerMode: true,
		centerPadding: '60px',
		prevArrow: '<button type="button" class="slick-prev TestimonialSlider-button TestimonialSlider-button--prev"></button>',
		nextArrow: '<button type="button" class="slick-next TestimonialSlider-button TestimonialSlider-button--next"></button>',
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					centerPadding: '150px'
				} 
			},
			{
				breakpoint: 1200,
				settings: {
					centerPadding: '100px'
				} 
			},
			{
				breakpoint: 992,
				settings : {
					centerMode: false,
					centerPadding: '0px'
				}
			},
			{
				breakpoint: 768,
				settings : {
					centerMode: false,
					centerPadding: '0px'
				}
			}
		]
	});
})(jQuery);
