/*global define, console*/

(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ScrollEffects", ["jquery", "Behaviors"], factory);
    } else {
        root.ScrollEffects = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";

    var module = {};

    function ScrollEffects(elem) {
        Behaviors.init(ScrollEffects, this, arguments);

        this.$elem = $(elem);
        this.$scrollCtxt = $(window);

        this.$scrollCtxt.on("scroll", Behaviors.throttle_single(this.on_scroll_intent.bind(this), 200));
    }

    Behaviors.inherit(ScrollEffects, Behaviors.Behavior);

    ScrollEffects.QUERY = "[data-scrolleffects]";
    ScrollEffects.THROTTLE_TIMEOUT = 200;

    /* Return a list of all available scroll effect modes on this bit.
     */
    ScrollEffects.prototype.activation_modes = function () {
        return this.$elem.data("scrolleffects").split(" ");
    };

    ScrollEffects.prototype.update_css_classes = function () {
        var activation_modes = this.activation_modes(),
            active = false;

        if (this.isTopVisible && activation_modes.indexOf("top_visible") !== -1) {
            active = true;
        }

        if (this.isBottomVisible && activation_modes.indexOf("bottom_visible") !== -1) {
            active = true;
        }

        if (this.isVisible && activation_modes.indexOf("visible") !== -1) {
            active = true;
        }

        if (this.onceTopVisible && activation_modes.indexOf("top_visible_once") !== -1) {
            active = true;
        }

        if (this.onceBottomVisible && activation_modes.indexOf("bottom_visible_once") !== -1) {
            active = true;
        }

        if (this.onceVisible && activation_modes.indexOf("visible_once") !== -1) {
            active = true;
        }

        if (active) {
            this.$elem.addClass("is-ScrollEffects--active");
            this.$elem.removeClass("is-ScrollEffects--inactive");
        } else {
            this.$elem.removeClass("is-ScrollEffects--active");
            this.$elem.addClass("is-ScrollEffects--inactive");
        }
    };

    ScrollEffects.prototype.on_scroll_intent = function () {
        var top = this.$elem.offset().top,
            height = this.$elem.height(),
            bottom = top + height,
            contextOffset = this.$scrollCtxt.offset(),
            contextScrollTop = contextOffset !== undefined
                                ? contextOffset.top + this.$scrollCtxt.scrollTop()
                                : this.$scrollCtxt.scrollTop(),
            contextHeight = this.$scrollCtxt.height(),
            contextScrollBottom = contextScrollTop + contextHeight;

        this.isTopVisible = contextScrollTop <= top && top <= contextScrollBottom;
        this.isBottomVisible = contextScrollTop <= bottom && bottom <= contextScrollBottom;
        this.isVisible = this.isTopVisible || this.isBottomVisible
            || (top <= contextScrollTop && contextScrollTop <= bottom)
            || (top <= contextScrollBottom && contextScrollBottom <= bottom);

        this.onceTopVisible = this.onceTopVisible || this.isTopVisible;
        this.onceBottomVisible = this.onceBottomVisible || this.isBottomVisible;
        this.onceVisible = this.onceVisible || this.isVisible;

        this.update_css_classes();
    };

    Behaviors.register_behavior(ScrollEffects);

    module.ScrollEffects = ScrollEffects;

    return module;
}));
