
// portfolio inner heroheader slider
(function($){
    $("[data-heroheader-imageslideshow]").slick({
        autoplay: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 6000,
        fade: true,
        arrows: true,
        slidesToShow: 1,
        pauseOnFocus: false,
        pauseOnHover: false,
        prevArrow: '<button type="button" class="slick-prev PortfolioSlider--button PortfolioSlider-prev--button"></button>',
        nextArrow: '<button type="button" class="slick-next PortfolioSlider--button PortfolioSlider-next--button"></button>',
    });

    var backButton = $("[data-back]");

    backButton.click(function(){
        window.history.back();
    });
})(jQuery);

// home button overlap 







// // home heroheader smoothscroll

(function ($) {
    $('a[href^=\\#]').click(function() {
        if (!this.hash) return;
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var $target = $(this.hash);
          $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
            
            if ($($(this).attr("href")).data("tabbedcontent-region") !== undefined) return;

          if ($target.length) {
            var height = $('.SiteHeader').outerHeight(true);
            if($('body').hasClass('admin-bar')) {
                if($(window).width() >= 768) {
                    var targetOffset = $target.offset().top - $('.SiteHeader').outerHeight(true) - 32;
                }
                else {
                    var targetOffset = $target.offset().top - $('.SiteHeader').outerHeight(true);
                }
            }
            else {
                var targetOffset = $target.offset().top - $('.SiteHeader').outerHeight(true);
            }
            $('html,body').animate({scrollTop: targetOffset}, 500);
                return false;
            }
        }
    });
}(jQuery));