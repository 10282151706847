(function($){

	$(".PostSlider--slides").slick({
		arrows: true,
		slidesToShow: 1,
		centerMode: true,
		centerPadding: '245px',
		prevArrow: '<button type="button" class="slick-prev PostSlider--button PostSlider-prev--button"></button>',
		nextArrow: '<button type="button" class="slick-next PostSlider--button PostSlider-next--button"></button>',
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					centerPadding: '150px'
				} 
			},
			{
				breakpoint: 1200,
				settings: {
					centerPadding: '100px',
					dots: true,
					dotsClass: "ImageSlider-dots",
                arrows: false,
				} 
			},
			{
				breakpoint: 992,
				settings : {
					centerMode: false,
					centerPadding: '0px',
					dots: true,
					dotsClass: "ImageSlider-dots",
                arrows: false,
				}
			},
			{
				breakpoint: 768,
				settings : {
					centerMode: false,
					centerPadding: '0px',
					dots: true,
					dotsClass: "ImageSlider-dots",
                    arrows: false,
				}
			}
		]

	});

	$(window).resize(function(){
		var slideSliderdots = $('.ImageSlider-dots button');
		for(var i = 0; i < slideSliderdots.length; i++){
			slideSliderdots[i].innerHTML = "";
		}
	})

	var slideSliderdots = $('.ImageSlider-dots button');
	for(var i = 0; i < slideSliderdots.length; i++){
		slideSliderdots[i].innerHTML = "";
	}

})(jQuery);
