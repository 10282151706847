(function ($) {

    $(document).ready(function() {
        $(".ApplicationForm--resume label").addClass("Basic-link");


        var resume = $(".ApplicationForm--resume input");
        var form = $(".ApplicationForm--resume");
        var p = $("<p class='file-name'> </p>");

        resume.change(function(e) {
            p.html("");
            var fileName = e.target.files[0].name;
            p.html(String(fileName));
            form.append(p);
        });


    });
    $(document).ajaxStop(function() {
        $(".ApplicationForm--resume label").addClass("Basic-link");


        var resume = $(".ApplicationForm--resume input");
        var form = $(".ApplicationForm--resume");
        var p = $("<p class='file-name'> </p>");

        resume.change(function(e) {
            p.html("");
            var fileName = e.target.files[0].name;
            p.html(String(fileName));
            form.append(p);
        });

    });

    /*$(document).ready(function() {
        resume.change(function(e) {
            p.html("");
            var fileName = e.target.files[0].name;
            p.html(String(fileName));
            form.append(p);
        });
    });*/

}(jQuery));
